<template>
    <div :class="className">
        <div v-if="showGdrp" :class="invalid ? 'invalid' : 'valid'">
            <k-checkbox v-model="gdpr.gdprValue">
                <template v-slot:label>
                    <span :class="invalid ? 'invalid' : 'valid'">
                        <template>{{ $t('gdpr.default_agree') }} </template>

                        <a href="/ochrana-osobnych-udajov" target="_blank" class="kod-link" @click="handleTermsClick">
                            <template>{{ $t('gdpr.personal_data') }}</template>
                        </a>
                    </span>
                </template>
            </k-checkbox>
            <v-tooltip v-if="!!gdprTooltip" top max-width="600" :disabled="!gdprTooltip" color="rgba(0, 0, 0, 0.75)" class="tw-z-50">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <k-icon name="info" />
                    </div>
                </template>
                <p v-html="gdprTooltip"></p>
            </v-tooltip>
        </div>
        <div v-if="showNewsletter" :class="invalidNewsletter ? 'invalid' : 'valid'">
            <k-checkbox v-model="gdpr.newsletterValue">
                <template v-slot:label>
                    <span :class="invalidNewsletter ? 'invalid' : 'valid'">
                        {{ $t(newsletterLabelText) }}
                    </span>
                </template>
            </k-checkbox>
            <v-tooltip v-if="!!newsletterTooltip" top max-width="600" :disabled="!newsletterTooltip" color="rgba(0, 0, 0, 0.75)" class="tw-z-50">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <k-icon name="info" />
                    </div>
                </template>
                <p v-html="newsletterTooltip"></p>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import {getRouteLocale} from '@web/constants/language'

export default {
    props: {
        invalid: Boolean,
        invalidNewsletter: Boolean,
        showGdrp: {
            type: Boolean,
            default: true
        },
        showNewsletter: {
            type: Boolean,
            default: true
        },
        gdprTooltip: {
            type: String,
            default: null
        },
        newsletterTooltip: {
            type: String,
            default: null
        },
        newsletterLabelText: {
            type: String,
            default: 'gdpr.descriptions.project_interest_agree_to_contact'
        },
        returnNegativeNewsletterValue: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            gdpr: {
                gdprValue: !this.showGdrp,
                newsletterValue: !this.showNewsletter
            }
        };
    },
    watch: {
        gdpr: {
            deep: true,
            handler() {
                const gdpr = {
                    ...this.gdpr,
                    newsletterValue: !this.gdpr.newsletterValue
                }

                this.$emit("input", this.returnNegativeNewsletterValue ? gdpr : this.gdpr);
            }
        }
    },
    computed: {
        className() {
            return "kod-gdpr-checkbox " + this.class;
        },
        routeLocale() {
            const locale = getRouteLocale();

            return locale ? `/${locale}` : '';
        }
    },
    created() {
        this.$emit("input", this.gdpr);
    },
    methods: {
        handleTermsClick() {
            var link = document.createElement("a");
            link.setAttribute("href", window.location.origin + this.routeLocale + "/ochrana-osobnych-udajov");
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener");
            link.dispatchEvent(new MouseEvent("click"));
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

::v-deep .v-input__slot {
    display: flex;
    align-items: flex-start;
}

::v-deep.kod-checkbox label {
    font-size: 0.875rem;
    margin-left: 1rem;
}

span.invalid,
span.invalidNewsletter {
    color: $koderia-red-alternative;
}

span.invalid .kod-link {
    color: $koderia-red-alternative !important;
}
</style>
