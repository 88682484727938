import { render, staticRenderFns } from "./k-gdpr.vue?vue&type=template&id=72235dfe&scoped=true&"
import script from "./k-gdpr.vue?vue&type=script&lang=js&"
export * from "./k-gdpr.vue?vue&type=script&lang=js&"
import style0 from "./k-gdpr.vue?vue&type=style&index=0&id=72235dfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72235dfe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KCheckbox from '@web/components/core/k-checkbox'
import KIcon from '@web/components/core/k-icon'
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {KCheckbox,KIcon,VTooltip})
