<template>
    <v-checkbox v-bind="$attrs" v-on="$listeners" :class="className" hide-details :ripple="false">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </v-checkbox>
</template>

<script>
export default {
    model: {
        prop: "input-value",
        event: "change"
    },
    computed: {
        className() {
            let className = "kod-checkbox";
            return `${className} ${this.class}`;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

//checkboxes
::v-deep.kod-checkbox {
    label {
        color: $kod-black !important;
        margin-left: 0.5rem;
    }
    &.v-input--selection-controls {
        margin-right: 0.813rem;
        margin-top: 0;
        padding-top: 0;
        .v-input--selection-controls__input {
            @include margin-right-multiplier(0);
        }
    }
    &.v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
        margin-bottom: 0;

        > .v-input--selection-controls__input {
            @include margin-right-multiplier(1);
        }
    }
    .v-icon {
        font-size: 1.286rem;
        color: $kod-light-green !important;
    }
    .v-icon.fa-square,
    .accent--text {
        color: $kod-black !important;
    }
    [aria-hidden="true"].accent--text {
        color: $kod-light-green !important;
    }
}
</style>
